import { UilTimes } from '@iconscout/react-unicons';
import React, { ReactNode } from 'react';
import toast, { resolveValue, Toaster, ToastType } from 'react-hot-toast';

import { cn } from '@/lib/utils';

export const ToastMessage = (props: {
  variant: ToastType;
  onClose?: () => unknown;
  children?: ReactNode;
  visible: boolean;
}) => {
  const { variant, children, onClose, visible } = props;

  return (
    <div
      className={cn(
        `header-5-m z-[1000] flex w-343 items-start justify-between gap-14 rounded-8 p-16 text-white`,
        `bg-gray-10`,
        variant === `success` && `bg-orange-300`,
        variant === `error` && `bg-danger-red-100`,
        visible ? `animate-fade-in` : `animate-fade-out`,
      )}
    >
      <div className="flex h-full items-center">{children}</div>
      <div className="flex items-start">
        <button className="size-24" onClick={onClose} type="button">
          <UilTimes className="size-24" />
        </button>
      </div>
    </div>
  );
};

function CustomToaster() {
  return (
    <Toaster position="bottom-left">
      {(t) => (
        <ToastMessage
          visible={t.visible}
          variant={t.type}
          onClose={() => toast.dismiss(t.id)}
        >
          {resolveValue(t.message, t)}
        </ToastMessage>
      )}
    </Toaster>
  );
}

export default CustomToaster;
